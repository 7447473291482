import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Logo from '../assets/Logo 2.png'

const Footer = () => {
    const current = new Date();
    const year = current.getFullYear();

    const service = () => {
        const section = document.querySelector('#service-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const about = () => {
        const section = document.querySelector('#about-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const news = () => {
        const section = document.querySelector('#news-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <div className="footer py-3">
            <Container>
                <Row>
                    <Col><img className='logo-footer' src={Logo} alt="Logo"></img><span><strong>PT. RESTU INTI NUSA ABADI</strong></span></Col>
                </Row>
                <Row>
                    <Col lg={true} className="py-3">
                        <strong>© {year} RESTU INVESTAMA</strong>
                    </Col>
                    <Col lg={true} className="py-3">
                        <strong>COMPANY</strong>
                        <div>
                            <Nav onClick={about} className="footer-link" as={Link}>About Us</Nav>
                        </div>
                        <div>
                            <Nav onClick={service} className="footer-link" as={Link}>Service</Nav>
                        </div>
                        <div>
                            <Nav onClick={news} className="footer-link" as={Link}>News</Nav>
                        </div>
                    </Col>
                    <Col lg={true} className="py-3">
                        <strong>CONTACT</strong>
                        <p>Email : admin@restuinvestama.com<br />Telepon : (021) 29303670</p>
                        <p>Gedung Gandaria 8 Oﬃce Tower Unit 16 D-E
                            Jl. Sultan Iskandar muda No.10, kelurahan kebayoran lama utara, Kecamatan kebayoran Lama, kota administrasi Jakarta selatan, provinsi DKI Jakarta, Indonesia
                        </p>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default Footer