import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Advantage = () => {
    return (
        <div>
            <Container className="mt-3">
                <Row><Col><h2 className='font-2 py-5'>ADVANTAGES OF GO PUBLIC</h2></Col></Row>
                <Row className='py-3'>
                    <Col lg={true} className=" value-align-vertical pb-5"><h2 className='font-2'>Advantages for the Company</h2></Col>
                    <Col lg={true}>
                        <p className="font-4">
                            <ul>
                                <li>Long term financing for working capital, debt repayment, investment or acquisition;</li>
                                <li>Increasing the company's equity value for optimal capital structure;</li>
                                <li>Obtaining further funding in the capital market;</li>
                                <li>Acknowledged by Banking/other financial institutions therefore lending process could be easier and may enable lower interest rates;</li>
                                <li>Increasing company's image;</li>
                                <li>Facilitating company's access in debt securities issuance at competitive rates;</li>
                                <li>5% lower income tax incentive granted by government (as long as 40%of its shares listed and traded in Indonesia Stock Exchange and has at least 300 shareholders).</li>
                            </ul>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={true} className=" value-align-vertical pb-5"><h2 className='font-2'>Advantages for the Shareholders(Founder)</h2></Col>
                    <Col lg={true}>
                        <p className="font-4">
                            <ul>
                                <li>Increasing shareholders value;</li>
                                <li>Creating liquidity and exit strategy foR shareholders (founders);</li>
                                <li>Facilitating succession and regeneration;</li>
                                <li>Enhancing professionalism among the management and staﬀ;</li>
                                <li>Proceeds could be used to diversity into new business.</li>
                            </ul>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Advantage