import React from 'react'
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutImage from '../../assets/Picture 4.png'

const HomeAboutID = () => {

    const service = () => {
        const section = document.querySelector('#service-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={true} className="home-about-align-vertical">
                        <h2 className="font-2 mb-3">KEGIATAN KAMI</h2>
                        <div className="font-4">
                            <p>Restu Investama menyediakan layanan terintegrasi terkait aksi korporasi yang terdiri dari Penggalangan dana ekuitas baik penawaran publik dan swasta, Pembiayaan hutang baik penawaran publik dan swasta, Leveraged deal untuk pembiayaan bank korporasi, Pembiayaan saham, Penasihat keuangan perusahaan, Restrukturisasi perusahaan, Pembiayaan ekuitas swasta. Dengan dukungan spesialis keuangan perusahaan yang diakui sebagai profesional dengan berbagai pengalaman dan rekam jejak yang luar biasa, Restu Investama percaya diri untuk melayani klien dengan layanan berbasis solusi.</p>
                        </div>
                        <div><Link onClick={service}><button className="Button">Baca selengkapnya</button></Link></div>
                    </Col>
                    <Col lg={true}><img src={aboutImage} alt="About Background" className='responsive-image' /></Col>
                </Row>
            </Container>
        </div >
    )
}

export default HomeAboutID