import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/Logo 2.png'

function NavigationID({ languageTrue, languageFalse }) {

    const home = () => {
        const section = document.querySelector('#home-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const service = () => {
        const section = document.querySelector('#service-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const about = () => {
        const section = document.querySelector('#about-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const news = () => {
        const section = document.querySelector('#news-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const contact = () => {
        const section = document.querySelector('#contact-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div className="navigation">
            <Navbar collapseOnSelect expand="lg" fixed='top' className='bg-navbar'>
                <Container>
                    <Navbar.Brand href='/' className='text-white'><img src={Logo} className="navigation-logo-image" alt='logo'></img>RESTU INVESTAMA</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Item>
                                <Nav.Link onClick={home} className='text-white' eventKey="1" as={Link} >Beranda</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={about} className='text-white' eventKey="3" as={Link} >Tentang</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={service} className='text-white' eventKey="2" as={Link} >Servis</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={news} className='text-white' eventKey="4" as={Link} >Berita</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={contact} className='text-white' eventKey="5" as={Link} >Kontak</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <div className="btn-group">
                            <Link onClick={languageTrue}><button className='tombol-1'>EN</button></Link>
                            <Link onClick={languageFalse}><button className='tombol-2'>ID</button></Link>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div >
    );
}

export default NavigationID