import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import reneomaju from "../../assets/reneomaju.png"
import mangkuraja from "../../assets/mangkuraja.jpg"
import bmaw from "../../assets/logo-bmaw.png"
import hafar from "../../assets/hafar.png"
import kasbon from "../../assets/kasbon.png"
import hardy from "../../assets/hardy-classic.png"
import supratama from "../../assets/supratama.png"
import ragaPerkasa from "../../assets/raga_perkasa_ekaguna.png"
import mariza from "../../assets/mariza_foods.png"
import chemplast from "../../assets/chemplast_indo.png"
import mitraBerlian from "../../assets/mitra_berlian.png"
import atourin from "../../assets/atourin.jpg"
import harnic from "../../assets/harnic.png"
import revolutik from "../../assets/revolutik_dananjaya_mandiri.png"
import enerflow from "../../assets/enerflow.jpg"
import cattle from "../../assets/indo_cattle_makmur.png"
import sansekerta from "../../assets/sansekerta.jpg"
import nusapangan from "../../assets/nusapangan.png"
import cariMobil from "../../assets/cari_mobil.png"
import ckt from "../../assets/ckt.png"

const CompanyPartner = () => {
    //     const [description, setDescription] = useState("")

    //     const [screenSize, getDimension] = useState({
    //         dynamicWidth: window.innerWidth,
    //         dynamicHeight: window.innerHeight
    //     });
    //     const setDimension = () => {
    //         getDimension({
    //             dynamicWidth: window.innerWidth,
    //             dynamicHeight: window.innerHeight
    //         })
    //     }

    //     useEffect(() => {
    //         window.addEventListener('resize', setDimension);

    //         return (() => {
    //             window.removeEventListener('resize', setDimension);
    //         })
    //     }, [screenSize])

    const [showAll, setShowAll] = useState(false);

    return (
        <div>
            <Container>
                <Row className="pb-3"><h2 className='font-2'>COMPANIES THAT HAVE MET AND ASSESSED WITH US</h2></Row>
                <Row>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={ckt} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. CITRA KARYA TOBINDO</p>
                            </div>
                            <div className='partner-description'>
                                <p>Jakarta</p>
                                <p>PT. CITRA KARYA TOBINDO is a procurement and general contractors</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={bmaw} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. BERKAH MULYA ABADI WONOSOBO</p>
                            </div>
                            <div className='partner-description'>
                                <p>Wonosobo</p>
                                <p>PT Berkah Mulya Abadi Wonosobo (BMAW) is a wood manufacturer based in Wonosobo, Central Java Indonesia. We produce veneer faced Black board and Plywood to fulfill local and international markets</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={reneomaju} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. RENEO MAJU BERSAMA</p>
                            </div>
                            <div className='partner-description'>
                                <p>Jakarta</p>
                                <p>PT. Reneo Maju Bersama is a procurement company supplying convection goods and foods.</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {showAll === true ? (
                    <div>
                        <Row>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mangkuraja} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>CV. MANGKURAJA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>East Borneo</p>
                                        <p>CV Mangkuraja is a Coal stock and supplier.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={hafar} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>HAFAR GROUP</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Singapore</p>
                                        <p>The Hafar Group is composed of a group of oﬀshore business companies engaged in EPCI (Engineering, Procurement, Construdion and Installation) services.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={kasbon} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. GLOBAL DINAMIKA INTERNUSA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Bandung</p>
                                        <p>Assist with easy access to funding and basic needs for the community.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={hardy} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>HARDY CLASSIC</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Bekasi</p>
                                        <p>Engaged in the automotive sector, especially for CAR SEATS who are members of the Indonesia Authorized Trimmer Summit (IATS)</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={supratama} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. SUPRATAMA MAKMUR SEJAHTERA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>An established regional poultry business group having been in a unique rural farming combined with the new technologies for production, supply chain and finance.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={ragaPerkasa} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT RAGA PERKASA EKAGUNA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>A national construction company that started from partial construction services to integrated Engineering, Procurement, Construction and Installation (EPCI) services.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mariza} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT MARIZARASA SARIMURNI</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>A home industry business</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={chemplast} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT CHEMPLAST INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>A company that provides packaging.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mitraBerlian} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>MITRA BERLIAN FARM</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>An integrated farm</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={atourin} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT ATOURIN TEKNOLOGI NUSANTARA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>As a technology company in the tourism sector that provides one-stop-solution services to tourists.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={harnic} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT HARNIC ONLINE STORE</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>JABODETABEK</p>
                                        <p>An online store taht sells a wide range of products to the consumers in the indonesian markets mainly focusing on household and daily use products.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={enerflow} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT ENERFLOW ENGINEERING INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Cikarang</p>
                                        <p>Focuses on assisting the industrial operations of the diesel power station owned by PT. PLN. and also contribute to activities related to a clean environment.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={revolutik} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT REVOLUTK DANANJAYA MANDIRI</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Cikarang</p>
                                        <p>Capable of product design and manufacturing as well as providing service to oil and gas company.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={cattle} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. INDO CATTLE MAKMUR</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Pasuruan</p>
                                        <p>General Trading & Supplier</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={sansekerta} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT SANSEKERTA MEDIKA INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Company engaged in the health sector which includes health services, construction, pharmacy, and medical equipment providers.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={nusapangan} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT NUSAPANGAN SUKSES MAKMUR</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Tangerang</p>
                                        <p>Focus on ready to meals, ready to serve/cook and beverage.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={cariMobil} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>CARIMOBIL</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Platform that eliminates most legacy problem associated with the pre-owned car industry purchase process.</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                ) : <div></div>}
                {showAll === false ? <div onClick={() => setShowAll(true)} className="Button mt-5">Show all</div> : <div onClick={() => setShowAll(false)} className="Button mt-5">Show less</div>}
                {/* <Row className="pb-3"><h2 className='font-2'>COMPANIES THAT HAVE MET AND ASSESSED WITH US</h2></Row>
                <Row>
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={screenSize.dynamicWidth >= 992 ? 3 : screenSize.dynamicWidth >= 768 ? 2 : 1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. BERKAH MULYA ABADI WONOSOBO")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={bmaw} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. BERKAH MULYA ABADI WONOSOBO</h4>
                                            <p>Wonosobo</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card HAFAR GROUP")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={hafar} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>HAFAR GROUP</h4>
                                            <p>Singapore</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. GLOBAL DINAMIKA INTERNUSA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={kasbon} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. GLOBAL DINAMIKA INTERNUSA</h4>
                                            <p>Bandung</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card HARDY CLASSIC")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={hardy} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>HARDY CLASSIC</h4>
                                            <p>Bekasi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. SUPRATAMA MAKMUR SEJAHTERA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={supratama} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. SUPRATAMA MAKMUR SEJAHTERA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT RAGA PERKASA EKAGUNA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={ragaPerkasa} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT RAGA PERKASA EKAGUNA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT MARIZARASA SARIMURNI")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={mariza} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT MARIZARASA SARIMURNI</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT CHEMPLAST INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={chemplast} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT CHEMPLAST INDONESIA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card MITRA BERLIAN FARM")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={mitraBerlian} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>MITRA BERLIAN FARM</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT ATOURIN TEKNOLOGI NUSANTARA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={atourin} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h3>PT ATOURIN TEKNOLOGI NUSANTARA</h3>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT HARNIC ONLINE STORE")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={harnic} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT HARNIC ONLINE STORE</h4>
                                            <p>JABODETABEK</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT ENERFLOW ENGINEERING INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={enerflow} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT ENERFLOW ENGINEERING INDONESIA</h4>
                                            <p>Cikarang</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. INDO CATTLE MAKMUR")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={cattle} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. INDO CATTLE MAKMUR</h4>
                                            <p>Pasuruan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT SANSEKERTA MEDIKA INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={sansekerta} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT SANSEKERTA MEDIKA INDONESIA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT NUSAPANGAN SUKSES MAKMUR")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={nusapangan} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT NUSAPANGAN SUKSES MAKMUR</h4>
                                            <p>Tangerang</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card CARIMOBIL")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={cariMobil} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>CARIMOBIL</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Row>
                <Row className='company-partner-description'>
                    <Card>
                        <Card.Text>Description</Card.Text>
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </Card>
                </Row> */}
            </Container>
        </div >
    )
}

export default CompanyPartner