import React from 'react'
import './Service.css'
import LineOfBusinessID from './LineOfBusinessID.js'
import CorporateActionID from './CorporateActionID.js'
import CompanyPartnerID from './CompanyPartnerID.js'
import ManagementID from '../About/ManagementID'
import AdvantageID from './AdvantageID'
import IpoID from './IpoID'

const ServiceID = () => {
    return (
        <div>
            <div className='line-of-business pb-5'>
                <LineOfBusinessID />
            </div>
            <div className='corporate-action'>
                <CorporateActionID />
            </div>
            <div className='advantage'>
                <AdvantageID />
            </div>
            <div className='ipo'>
                <IpoID />
            </div>
            <div className='about-management'>
                <ManagementID />
            </div>
            <div className='company-partner '>
                <CompanyPartnerID />
            </div>
        </div>
    )
}

export default ServiceID