import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import reneomaju from "../../assets/reneomaju.png"
import mangkuraja from "../../assets/mangkuraja.jpg"
import bmaw from "../../assets/logo-bmaw.png"
import hafar from "../../assets/hafar.png"
import kasbon from "../../assets/kasbon.png"
import hardy from "../../assets/hardy-classic.png"
import supratama from "../../assets/supratama.png"
import ragaPerkasa from "../../assets/raga_perkasa_ekaguna.png"
import mariza from "../../assets/mariza_foods.png"
import chemplast from "../../assets/chemplast_indo.png"
import mitraBerlian from "../../assets/mitra_berlian.png"
import atourin from "../../assets/atourin.jpg"
import harnic from "../../assets/harnic.png"
import revolutik from "../../assets/revolutik_dananjaya_mandiri.png"
import enerflow from "../../assets/enerflow.jpg"
import cattle from "../../assets/indo_cattle_makmur.png"
import sansekerta from "../../assets/sansekerta.jpg"
import nusapangan from "../../assets/nusapangan.png"
import cariMobil from "../../assets/cari_mobil.png"
import ckt from "../../assets/ckt.png"

const CompanyPartnerID = () => {
    //     const [description, setDescription] = useState("")

    //     const [screenSize, getDimension] = useState({
    //         dynamicWidth: window.innerWidth,
    //         dynamicHeight: window.innerHeight
    //     });
    //     const setDimension = () => {
    //         getDimension({
    //             dynamicWidth: window.innerWidth,
    //             dynamicHeight: window.innerHeight
    //         })
    //     }

    //     useEffect(() => {
    //         window.addEventListener('resize', setDimension);

    //         return (() => {
    //             window.removeEventListener('resize', setDimension);
    //         })
    //     }, [screenSize])

    const [showAll, setShowAll] = useState(false);

    return (
        <div>
            <Container>
                <Row className="pb-3"><h2 className='font-2'>PERUSAHAAN YANG TELAH BERTEMU DAN MELAKUKAM ASESMEN DENGAN KAMI</h2></Row>
                <Row>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={ckt} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. CITRA KARYA TOBINDO</p>
                            </div>
                            <div className='partner-description'>
                                <p>Jakarta</p>
                                <p>PT. CITRA KARYA TOBINDO adalah kontraktor pengadaan dan umum</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={bmaw} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. BERKAH MULYA ABADI WONOSOBO</p>
                            </div>
                            <div className='partner-description'>
                                <p>Wonosobo</p>
                                <p>PT Berkah Mulya Abadi Wonosobo (BMAW) adalah produsen kayu yang berbasis di Wonosobo, Jawa Tengah Indonesia. Kami memproduksi veneer faced Black board dan Plywood untuk memenuhi pasar lokal dan internasional.</p>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={true}>
                        <Card className='partner-card'>
                            <div className='partner-image'>
                                <img src={reneomaju} />
                            </div>
                            <div className='partner-name'>
                                <p>PT. RENEO MAJU BERSAMA</p>
                            </div>
                            <div className='partner-description'>
                                <p>Jakarta</p>
                                <p>CV Mangkuraja adalah perusahaan stock dan supplier batubara.</p>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {showAll === true ? (
                    <div>
                        <Row>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mangkuraja} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>CV. MANGKURAJA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Kalimantan Timur</p>
                                        <p>CV Mangkuraja adalah CV Commanditaire Vennootschap atau persekutuan terbatas yang didirikan berdasarkan Hukum Indonesia.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={hafar} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>HAFAR GROUP</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Singapore</p>
                                        <p>Hafar Group terdiri dari sekelompok perusahaan bisnis lepas pantai yang bergerak di bidang jasa EPCI (Engineering, Procurement, Construdion and Installation).</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={kasbon} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. GLOBAL DINAMIKA INTERNUSA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Bandung</p>
                                        <p>Membantu kemudahan akses pendanaan dan kebutuhan dasar bagi masyarakat.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={hardy} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>HARDY CLASSIC</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Bekasi</p>
                                        <p>Bergerak di bidang otomotif, khususnya untuk CAR SEATS yang tergabung dalam Indonesia Authorized Trimmer Summit (IATS)</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={supratama} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. SUPRATAMA MAKMUR SEJAHTERA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Sebuah kelompok bisnis unggas regional yang mapan yang telah berada dalam pertanian pedesaan yang unik dikombinasikan dengan teknologi baru untuk produksi, rantai pasokan dan keuangan.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={ragaPerkasa} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT RAGA PERKASA EKAGUNA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Perusahaan konstruksi nasional yang berawal dari jasa konstruksi parsial menjadi jasa Engineering, Procurement, Construction and Installation (EPCI) yang terintegrasi.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mariza} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT MARIZARASA SARIMURNI</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Bisnis industri rumah tangga</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={chemplast} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT CHEMPLAST INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Perusahaan yang menyediakan pengemasan.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={mitraBerlian} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>MITRA BERLIAN FARM</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Pertanian terpadu</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={atourin} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT ATOURIN TEKNOLOGI NUSANTARA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Sebagai perusahaan teknologi di sektor pariwisata yang menyediakan layanan one-stop-solution kepada wisatawan.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={harnic} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT HARNIC ONLINE STORE</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>JABODETABEK</p>
                                        <p>Sebuah toko online yang menjual berbagai macam produk kepada konsumen di pasar Indonesia terutama berfokus pada produk rumah tangga dan penggunaan sehari-hari.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={enerflow} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT ENERFLOW ENGINEERING INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Cikarang</p>
                                        <p>Berfokus pada pendampingan operasional industri pembangkit listrik tenaga diesel yang dimiliki oleh PT. PLN. dan juga berkontribusi dalam kegiatan yang berkaitan dengan lingkungan yang bersih.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={revolutik} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT REVOLUTK DANANJAYA MANDIRI</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Cikarang</p>
                                        <p>Mampu mendesain dan membuat produk serta memberikan layanan kepada perusahaan minyak dan gas.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={cattle} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT. INDO CATTLE MAKMUR</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Pasuruan</p>
                                        <p>Perdagangan Umum & Pemasok</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={sansekerta} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT SANSEKERTA MEDIKA INDONESIA</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Perusahaan yang bergerak di bidang kesehatan yang meliputi jasa kesehatan, konstruksi, farmasi, dan penyedia peralatan medis.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={nusapangan} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>PT NUSAPANGAN SUKSES MAKMUR</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Tangerang</p>
                                        <p>Fokus pada makanan siap saji, siap saji/masak dan minuman.</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col lg={true}>
                                <Card className='partner-card'>
                                    <div className='partner-image'>
                                        <img src={cariMobil} />
                                    </div>
                                    <div className='partner-name'>
                                        <p>CARIMOBIL</p>
                                    </div>
                                    <div className='partner-description'>
                                        <p>Jakarta</p>
                                        <p>Platform yang menghilangkan sebagian besar masalah lama yang terkait dengan proses pembelian industri mobil bekas.</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                ) : <div></div>}
                {showAll === false ? <div onClick={() => setShowAll(true)} className="Button mt-5">Tampilkan semua</div> : <div onClick={() => setShowAll(false)} className="Button mt-5">Tampilkan sebagian</div>}
                {/* <Row className="pb-3"><h2 className='font-2'>COMPANIES THAT HAVE MET AND ASSESSED WITH US</h2></Row>
                <Row>
                    <Swiper
                        spaceBetween={5}
                        slidesPerView={screenSize.dynamicWidth >= 992 ? 3 : screenSize.dynamicWidth >= 768 ? 2 : 1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. BERKAH MULYA ABADI WONOSOBO")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={bmaw} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. BERKAH MULYA ABADI WONOSOBO</h4>
                                            <p>Wonosobo</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card HAFAR GROUP")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={hafar} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>HAFAR GROUP</h4>
                                            <p>Singapore</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. GLOBAL DINAMIKA INTERNUSA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={kasbon} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. GLOBAL DINAMIKA INTERNUSA</h4>
                                            <p>Bandung</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card HARDY CLASSIC")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={hardy} />
                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>HARDY CLASSIC</h4>
                                            <p>Bekasi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. SUPRATAMA MAKMUR SEJAHTERA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={supratama} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. SUPRATAMA MAKMUR SEJAHTERA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT RAGA PERKASA EKAGUNA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={ragaPerkasa} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT RAGA PERKASA EKAGUNA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT MARIZARASA SARIMURNI")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={mariza} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT MARIZARASA SARIMURNI</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT CHEMPLAST INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={chemplast} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT CHEMPLAST INDONESIA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card MITRA BERLIAN FARM")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={mitraBerlian} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>MITRA BERLIAN FARM</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT ATOURIN TEKNOLOGI NUSANTARA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={atourin} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h3>PT ATOURIN TEKNOLOGI NUSANTARA</h3>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT HARNIC ONLINE STORE")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={harnic} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT HARNIC ONLINE STORE</h4>
                                            <p>JABODETABEK</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT ENERFLOW ENGINEERING INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={enerflow} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT ENERFLOW ENGINEERING INDONESIA</h4>
                                            <p>Cikarang</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT. INDO CATTLE MAKMUR")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={cattle} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT. INDO CATTLE MAKMUR</h4>
                                            <p>Pasuruan</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT SANSEKERTA MEDIKA INDONESIA")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={sansekerta} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT SANSEKERTA MEDIKA INDONESIA</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card PT NUSAPANGAN SUKSES MAKMUR")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={nusapangan} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>PT NUSAPANGAN SUKSES MAKMUR</h4>
                                            <p>Tangerang</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => setDescription("This is description of card CARIMOBIL")}>
                            <div className="container-partner">
                                <div className="card-partner">
                                    <div className="face face1">
                                        <div className="content">
                                            <img src={cariMobil} />

                                        </div>
                                    </div>
                                    <div className="face face2">
                                        <div className="content">
                                            <h4>CARIMOBIL</h4>
                                            <p>Jakarta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Row>
                <Row className='company-partner-description'>
                    <Card>
                        <Card.Text>Description</Card.Text>
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </Card>
                </Row> */}
            </Container>
        </div >
    )
}

export default CompanyPartnerID