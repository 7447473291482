import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const AdvantageID = () => {
    return (
        <div>
            <Container className="mt-3">
                <Row><Col><h2 className='font-2 py-5'>KEUNTUNGAN DARI GO PUBLIC</h2></Col></Row>
                <Row className='py-3'>
                    <Col lg={true} className=" value-align-vertical pb-5"><h2 className='font-2'>Keuntungan untuk Perusahaan</h2></Col>
                    <Col lg={true}>
                        <p className="font-4">
                            <ul>
                                <li>Pembiayaan jangka panjang untuk modal kerja, pembayaran utang, investasi atau akuisisi;</li>
                                <li>Meningkatkan nilai ekuitas perusahaan untuk struktur modal yang optimal;</li>
                                <li>Memperoleh pendanaan lebih lanjut di pasar modal;</li>
                                <li>Diakui oleh Perbankan/lembaga keuangan lainnya sehingga proses peminjaman dapat lebih mudah dan memungkinkan suku bunga yang lebih rendah;</li>
                                <li>Meningkatkan citra perusahaan;</li>
                                <li>Memfasilitasi akses perusahaan dalam penerbitan surat utang dengan harga yang kompetitif;</li>
                                <li>Insentif pajak penghasilan 5% lebih rendah yang diberikan oleh pemerintah (selama 40% sahamnya terdaftar dan diperdagangkan di Bursa Efek Indonesia dan memiliki setidaknya 300 pemegang saham).</li>
                            </ul>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={true} className=" value-align-vertical pb-5"><h2 className='font-2'>Keuntungan bagi Pemegang Saham (Pendiri)</h2></Col>
                    <Col lg={true}>
                        <p className="font-4">
                            <ul>
                                <li>Meningkatkan nilai pemegang saham;</li>
                                <li>Menciptakan likuiditas dan strategi keluar bagi pemegang saham (pendiri);</li>
                                <li>Memfasilitasi suksesi dan regenerasi;</li>
                                <li>Meningkatkan profesionalisme di antara manajemen dan staf;</li>
                                <li>Hasil penjualan bisa digunakan untuk diversifikasi ke dalam bisnis baru.</li>
                            </ul>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AdvantageID