import React from 'react'
import Container from 'react-bootstrap/Container'
import ipoImage from '../../assets/ipo.png'

const Ipo = () => {
    return (
        <div>
            <Container>
                <div className='py-5'><h2 className='font-2'>INITIAL PUBLIC OFFERING (IPO)</h2></div>
                <div className='py-5'><p className='font-4'>IPO or Initial Public Oﬀering is an activity of stock oﬀering conducted by company/issuer to sell shares or securities to the public in accordance with procedures governed by capital market law and its implementation ordinance.</p></div>
                <img className='responsive-image' src={ipoImage} alt="ipo" />
                <div className='py-5'>
                    <p className="font-4">A & B : Shareholders (“Founder”)from PT/PTTBK </p>
                    <p className="font-4">Public : Public investors who buy shares over IPO PT : Company in limited liability form </p>
                    <p className="font-4">A & B : Shareholders (“Founder”) from PT/PTTBK </p>
                </div>
            </Container>
        </div>
    )
}

export default Ipo