import React from 'react'
import Container from 'react-bootstrap/Container'
import ipoImage from '../../assets/ipo.png'

const IpoID = () => {
    return (
        <div>
            <Container>
                <div className='py-5'><h2 className='font-2'>PENAWARAN UMUM PERDANA (IPO)</h2></div>
                <div className='py-5'><p className='font-4'>IPO atau Initial Public Oﬀering adalah kegiatan penawaran saham yang dilakukan oleh perusahaan/emiten untuk menjual saham atau efek kepada masyarakat sesuai dengan prosedur yang diatur oleh undang-undang pasar modal dan peraturan pelaksanaannya.</p></div>
                <img className='responsive-image' src={ipoImage} alt="ipo" />
                <div className='py-5'>
                    <p className="font-4">A & B : Pemegang Saham ("Pendiri") dari PT/PTTBK</p>
                    <p className="font-4">Publik : Investor publik yang membeli saham melalui IPO PT : Perusahaan dalam bentuk perseroan terbatas </p>
                    <p className="font-4">A & B : Pemegang Saham ("Pendiri") dari PT/PTTBK</p>
                </div>
            </Container>
        </div>
    )
}

export default IpoID