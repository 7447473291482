import React, { useState, useEffect } from 'react';
import './App.css';
import './style.css';
import './style.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from './components/Navigation.js';
import NavigationID from './components/NavigationID.js';
import Home from './components/Home/Home.js'
import HomeID from './components/Home/HomeID.js'
import About from './components/About/About.js';
import AboutID from './components/About/AboutID.js';
import Service from './components/Service/Service.js'
import ServiceID from './components/Service/ServiceID.js'
import Contact from './components/Contact/Contact.js';
import ContactID from './components/Contact/ContactID.js';
import News from './components/News/News.js';
import NewsID from './components/News/NewsID.js';
import Footer from './components/Footer.js';
import FooterID from './components/FooterID.js';
import ScrollToTop from './components/ScrollToTop';
import Ads from './components/Ads.js';

import HomeContact from './components/Home/HomeContact';
import HomeContactID from './components/Home/HomeContactID';

function App() {
  // const [language, setLanguage] = useState(true)


  // const [url, getUrl] = useState(window.location.pathname);
  const [language, setLanguage] = useState(true)

  const languageTrue = () => {
    setLanguage(true)
  }
  const languageFalse = () => {
    setLanguage(false)
  }

  useEffect(() => {
    window.addEventListener('language', languageTrue);

    return (() => {
      window.removeEventListener('language', languageTrue);
    })
  }, [language])

  useEffect(() => {
    window.addEventListener('language', languageFalse);

    return (() => {
      window.removeEventListener('language', languageFalse);
    })
  }, [language])

  return (
    <div className="App">
      <BrowserRouter>
        {language === true ? <Navigation languageTrue={languageTrue} languageFalse={languageFalse} /> : <NavigationID languageTrue={languageTrue} languageFalse={languageFalse} />}
        {/* <ScrollToTop /> */}
        <div className='home-scroll' id='home-scroll'>
          {language === true ? <Home /> : <HomeID />}
        </div>
        <div id='about-scroll'>
          {language === true ? <About /> : <AboutID />}
        </div>
        <div id='service-scroll'>
          {language === true ? <Service /> : <ServiceID />}
        </div>
        {/* <div className='pb-5' id='news-scroll'>
          {language === true ? <News /> : <NewsID />}
        </div> */}
        <Routes>
          {/* <Route path='/' element={<Home />} />
          <Route path='/service' element={<Service />} />
          <Route path='/about' element={<About />} />
          <Route path='/news' element={<News />} />
          <Route path='/contact' element={<Contact />} />

          <Route path='/id/beranda' element={<HomeID />} />
          <Route path='/id/servis' element={<ServiceID />} />
          <Route path='/id/tentang' element={<AboutID />} />
          <Route path='/id/berita' element={<NewsID />} />
          <Route path='/id/kontak' element={<ContactID />} />

          <Route path="/*" element={<Home />} /> */}
        </Routes>
        {/* <Ads /> */}
        <div className='py-5' id='contact-scroll'>
          {language === true ? <HomeContact /> : <HomeContactID />}
        </div>
        {language === true ? <Footer /> : <FooterID />}
      </BrowserRouter>
    </div>
  );
}

export default App;
