import React from 'react'
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import aboutImage from '../../assets/Picture 4.png'

const HomeAbout = () => {

    const service = () => {
        const section = document.querySelector('#service-scroll');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={true} className="home-about-align-vertical">
                        <h2 className="font-2 mb-3">WHAT WE DO</h2>
                        <div className="font-4">
                            <p>Restu Investama provides an integrated services related to corporate action consist of Equity fund raising both public and private offerings, Debt financing both public and private offerings, Leveraged deals for corporate bank financing, Share financing, Corporate finance advisory, Corporate  restructuring, Private equity financing. With the support of corporate finance specialist that is acknowledged as a professional with a wide range of experience and outstanding track record, Restu Investama is confident to serve clients with a solution based services.</p>
                        </div>
                        <div><Link onClick={service}><button className="Button">Read More</button></Link></div>
                    </Col>
                    <Col lg={true}><img src={aboutImage} alt="About Background" className='responsive-image' /></Col>
                </Row>
            </Container>
        </div >
    )
}

export default HomeAbout