import React from 'react'
import './Service.css'
import LineOfBusiness from './LineOfBusiness'
import CorporateAction from './CorporateAction'
import CompanyPartner from './CompanyPartner'
import Management from '../About/Management'
import Advantage from './Advantage'
import Ipo from './Ipo'

const Service = () => {
    return (
        <div>
            <div className='line-of-business pb-5'>
                <LineOfBusiness />
            </div>
            <div className='corporate-action'>
                <CorporateAction />
            </div>
            <div className='advantage'>
                <Advantage />
            </div>
            <div className='ipo'>
                <Ipo />
            </div>
            <div className='about-management'>
                <Management />
            </div>
            <div className='company-partner'>
                <CompanyPartner />
            </div>
        </div>
    )
}

export default Service